<section class="ecommerce pt-0 ecommerce-home">
    <div *ngIf="bannerLoader" class="skeleton" style="height: 80vh"></div>
    <div *ngIf="(bannerCount == 0) && (bannerLoader == false)" class="no-banner"></div>
    <owl-carousel-o [options]="ecombannerOptions" class=" ecommerce-home-slider">
        <ng-template carouselSlide  *ngFor="let banner of bannerList">
            <div class="item-class h-100 owl-items banncs"  (click)="navigateBanner(banner)">
                <!-- <div class="oneimg" [ngStyle]="{'background-image': 'url('+ banner.ResimUrl +')'}"></div> -->
                <div  class="twoimg" [ngStyle]="{'background-image': 'url('+ banner.ResimUrl +')'}"></div>
            </div>
        </ng-template>
    </owl-carousel-o>
    <div class="container">
        <div class="row banner-three">
            <div class="col-md-4" *ngFor="let collection of collectionList">
                <div class="main-wrapper mouse" [routerLink]="['/collection/'] + collection.HandleUrl">
                    <img alt="#" class="bg-img img-fluid b-rad" [src]="collection.ResimUrl">
                </div>
            </div>
            <div *ngIf="collectionLoader" class="col-12 row">
                <div *ngFor="let item of [1, 2, 3, 4, 5, 6]" class="col-md-4 skeleton" style="height: 350px;"></div>
            </div>
            <div *ngIf="(collectionCount == 0) && (collectionLoader == false)" class="col-12 row">
                <div *ngFor="let item of [0, 1, 2, 3, 4, 5]" class="col-md-4 no-collection">
                    <img src="../../../../../assets/images/inner-page/collection/1.jpg" alt="no collection">
                </div>
            </div>
        </div>
    </div>
</section>