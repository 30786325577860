import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AccountService } from './account.service';
import { Constants } from '../constants';

@Injectable()
export class BannerService {
    bannerList:Array<any>;
    textBanners: Array<any>;
    presentationBanners: Array<any>;
    constructor(
        private http: HttpClient,
        private accountService:AccountService
    ){ }

    list():Observable<any> {
        let companyId = this.accountService.getAccount ? this.accountService.getAccount['CompanyId'] : 0;
        let requestPayload = {
            ApiId: Constants.apiId,
            FirmaGuid: Constants.guid,
            PlatformId: Constants.platformId,
            Skip: 0,
            Take: 100,
            Aktif: true,
            CompanyId: companyId
        }
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': Constants.Authorization,
                'Content-Type': 'application/json'
            })
        }
        return this.http.post(`${Constants.b2cApiUrl}/banner/list`, requestPayload, httpOptions)
        .pipe(map(res => {
            if (res['result']['status']) {
                this.bannerList = res['data'];
                this.textBanners = this.textBannerList.length > 0 ? this.textBannerList : null;
                this.presentationBanners = this.presentationBannerList.length > 0 ? this.presentationBannerList : null;
                return res['data'];
            }else {
                return [];
            }
        }))
    }

    get textBannerList():any {
        return this.bannerList.filter(b => { return b.Tip == '0'});
    }

    get presentationBannerList(): any {
        return this.bannerList.filter(b => { return b.Tip == '1' });
    }
}