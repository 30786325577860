<footer class="ecommerce-footer" id="footer">
    <div class="container">
        <div class="row footer-theme partition-f">
            <div class="col-lg-4 col-md-12">
                <div class="footer-contant">
                    <div class="footer-logo"><a href="./" class="logo nunito-semibold">Shop ANV</a></div>
                    <div class="footer-social">
                        <ul>
                            <li><a href="https://www.facebook.com/anv.com.tr/notifications/" target="_blank"><i aria-hidden="true" class="fa fa-facebook"></i></a></li>
                            <li><a href="https://twitter.com/anvyazilim" target="_blank"><i aria-hidden="true" class="fa fa-twitter"></i></a></li>
                            <li><a href="https://www.instagram.com/anvyazilim/" target="_blank"><i aria-hidden="true" class="fa fa-instagram"></i></a></li>
                            <li><a href="https://www.linkedin.com/company/anv-kurumsal-yazilim-dani%C5%9Fmanlik-san-ti%CC%87c-ltd-%C5%9Fti%CC%87/?originalSubdomain=tr" target="_blank"><i aria-hidden="true" class="fa fa-linkedin"></i></a></li>
                        </ul>
                    </div>
                    <!-- <div class="mobile-app-links">
                        <h4>
                            Mobil Uygulama Linklerimiz:
                        </h4>
                        <div class="row">
                            <div class="col-6">
                                <a href="https://apps.apple.com/us/app/mobimbo/id1509691526" target="_blank">
                                    <img src="assets/images/appstore.png" alt="Mobimbo AppStore">
                                </a>
                            </div>
                            <div class="col-6">
                                <a href="https://play.google.com/store/apps/details?id=com.glopark.mobimbo&hl=tr" target="_blank">
                                    <img src="assets/images/googleplay.png" alt="Mobimbo Google Play">
                                </a>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="col offset-xl-1">
                <div class="sub-title">
                    <div class="footer-title">
                        <h4>Hesabım</h4>
                    </div>
                    <div class="footer-contant">
                        <ul>
                            <li><a href="javascript:void()" [routerLink]="['/hesabim']">Bilgilerim</a></li>
                            <li><a href="javascript:void()" [routerLink]="['/siparislerim']">Siparişlerim</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="sub-title">
                    <div class="footer-title">
                        <h4>Müşteri Hizmetleri</h4>
                    </div>
                    <div class="footer-contant">
                        <ul>
                            <li *ngFor="let cs of customerService.csList" id="title-h"><a [routerLink]="['/pages', convertString(cs.Baslik)]">{{turkLower(cs.Baslik)}}  </a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="sub-title">
                    <div class="footer-title">
                        <h4>Mağaza İletişim</h4>
                    </div>
                    <div class="footer-contant">
                        <ul class="contact-list">
                            <li><i class="fa fa-map-marker"></i>Mansuroğlu Mah. 288/4 sok. No:16 k:2 d:17 Bayraklı-İZMİR</li>
                            <li><i class="fa fa-phone"></i>İletişim: <a href="tel:05322657160">0532 265 71 60</a></li>
                            <li><i class="fa fa-envelope-o"></i>Email: <a href="mailto:zeynep@anv.com.tr">zeynep@anv.com.tr</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="sub-footer">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="footer-end row">
                        <p class="pt-0"><small><i aria-hidden="true" class="fa fa-copyright"></i> Copyright 2020 <a href="https://www.glopark.com/" target="_blank" class="copyright-link"><small>Glopark</small></a> | Tüm Hakları Saklıdır. Kredi kartı bilgileriniz 256bit
                            SSL sertifikası ile korunmaktadır.</small>
                        </p>
                        <div class="card-brands d-flex">
                            <img src="assets/images/icon/trushsafe-logo.jpg" alt="master">
                            <img src="assets/images/icon/master-logo.png" alt="master">
                            <img src="assets/images/icon/visa-logo.png" alt="master">
                            <img src="assets/images/icon/troy-logo.png" alt="master">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<app-tap-to-top></app-tap-to-top>
<a [href]="sanitize(whatsapp)" class="whatsapp-btn">
    <i aria-hidden="true" class="fa fa-whatsapp"></i>
</a>