import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  companyInfos = {
    name: 'Mobimbo',
    contact: {
      address: 'Gaziosmanpaşa Bulv. No: 41/A Çankaya – İZMİR',
      phones: [
        {
          title: 'İletişim',
          no: '0232 425 5556'
        },
      ],
      fullName: 'Samsung Yetkili Bayi / Datapark İç Ve Dış Tic.Ltd.Şti',      
      fax: '',
      email: 'mobimbo@mobimbo.com'
    },
    social: [
      {
        icon: 'fa fa-facebook',
        link: 'https://www.facebook.com/mobimbocom'
      },
      {
        icon: 'fa fa-twitter',
        link: 'https://twitter.com/mobimbooffical'
      },
      {
        icon: 'fa fa-instagram',
        link: 'https://www.instagram.com/mobimbo/'
      },
      {
        icon: 'fa fa-youtube',
        link: 'https://www.youtube.com/channel/UCcydbDD7yuOsgANa43KcihA'
      }
    ]
  }
  theme = {
    type: 'shop', // shop - gallery
    title: 'Mobimbo',
    logo: {
      selected: 'image', // text - image
      text: 'mobimbo',
      headerLogo: '',
      footerLogo: ''
    },
    favicon: '',
    maintenance: {
      active: false,
      leftImage:  ''
    },
    showNoStock: 1
  }

  constructor() { }

}