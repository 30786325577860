import { Component, OnInit, Input } from '@angular/core';
import { ProductsService } from '../../../service/e-commerce/products.service';
import { CartService } from '../../../service/e-commerce/cart.service';
import { WishListService } from '../../../service/e-commerce/wish-list.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QuickPreviewModalComponent } from './quick-preview-modal/quick-preview-modal.component';

@Component({
  selector: 'app-product-box',
  templateUrl: './product-box.component.html',
  styleUrls: ['./product-box.component.scss']
})
export class ProductBoxComponent implements OnInit {

  @Input() products: any;
  productPhotoOnListing = "";
  maths;

  constructor(
    public productsService: ProductsService,
    private modalService: NgbModal,
    private cartService: CartService,
    private wishlistService: WishListService
  ) { }

  ngOnInit() {
    this.productPhotoOnListing = this.products.image.length > 0 ? this.products.image[0].url : "assets/images/ecommerce/image-preparing.jpg";

    if (this.products.displayPrice > 0 && this.products.price > this.products.displayPrice) {
      let result = ((this.products.price - this.products.displayPrice) / this.products.price) * 100;
      this.maths = parseInt(String(result));
    }


  }

  openQuickPreview(handleUrl) {
    let modalRef = this.modalService.open(QuickPreviewModalComponent, { size: 'lg', ariaLabelledBy: 'modal-basic-title' });
    modalRef.componentInstance.handleUrl = handleUrl;

    modalRef.result.then(result => { }).catch(err => { /*console.log(err)*/ });
  }

  public addToCart(product: any, quantity: number = 1) {
    this.cartService.addToCart(product, quantity);
  }

  public addToCompare(product: any) {
    this.productsService.addToCompare(product);
  }

  // Add to wishlist
  public addToWishlist(product: any) {
    this.wishlistService.addToWishlist(product);
  }

}
