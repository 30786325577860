<!-- brand filter start -->
<div class="collection-collapse-block pt-0 open">
    <h3 class="collapse-block-title">Marka</h3>
    <div class="collection-collapse-block-content">
      <div class="collection-brand-filter">
        <div class="custom-control custom-checkbox collection-filter-checkbox" *ngFor="let filter of tagsFilters['Values']">
          <input type="checkbox" class="custom-control-input" [value]="filter.Value" (change)="checkedFilter($event)" id="{{filter.Value}}">
          <label class="custom-control-label" for="{{filter.Value}}">{{filter.Value}}</label>
        </div>
      </div>
    </div>
  </div>
  