<div class="img-wrapper pd-5 position-relative">
    <div *ngIf="maths > 0" class="ml-auto text-center sale-d">
        <span class="text-white mr3"> %{{maths}}</span>
    </div>
    <div class="front">
        <a [routerLink]="['/product/' + products.handleUrl]">
            <img alt="" class="img-fluid" [src]="productPhotoOnListing">
        </a>
    </div>
    <div class="cart-info cart-wrap">
        <!-- <button (click)="addToCart(products)" title="Add to cart"><i class="fa fa-shopping-cart" ></i></button>
        <a (click)="addToWishlist(products)" title="Add to Wishlist"><i class="fa fa-heart-o" aria-hidden="true"></i></a>
        <a routerLinkActive="active" (click)="addToCompare(products)" title="Compare"><i class="fa fa-refresh"aria-hidden="true"></i></a> -->
        <a href="javascript:void(0)" title="Hızlı İncele" (click)="openQuickPreview(products.handleUrl)"><i
                class="fa fa-search" aria-hidden="true"></i></a>
    </div>
</div>
<div class="product-detail">
    <a [routerLink]="['/product/' + products.handleUrl]">
        <h6>{{products.title }}</h6>
    </a>
    <p>{{products.subtitle}}</p>

    <div *ngIf="products.displayPrice > 0">
        <div *ngIf="products.displayPrice < products.price">
            <del>{{products.price}} TL</del>
            <h4>{{products.displayPrice}} TL</h4>
        </div>
        <div *ngIf="products.displayPrice >= products.price">
            <h4>{{products.displayPrice}} TL</h4>
        </div>
    </div>

    <div *ngIf="products.displayPrice <= 0">
        <h4>{{products.price}} TL</h4>
    </div>
</div>