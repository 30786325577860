export class Constants {
    public static get siteUrl(): string { return `https://shopanv.com`; };
    public static get guid(): string { return "205C94CA-63FF-4FAF-9FAE-438D460F3D4B"; };
    public static get apiId(): number { return 35; };
    public static get platformId(): number { return 19; };
    public static get gloparkApiUrl(): string { return `https://servis.glopark.com/api-b2c/api/b2c/${this.guid}`; };
    public static get b2cApiUrl(): string { return `https://servis.glopark.com/api-b2c/api/b2c/firma/${this.guid}`; };
    public static get pureB2cApiUrl(): string { return `https://servis.glopark.com/api-b2c/api/b2c`; };
    public static get contactsApiUrl(): string { return `https://servis.glopark.com/api-contacts/api/firma/${this.guid}`; };
    public static get b2bApiUrl(): string { return `https://servis.glopark.com/api-b2c/api/b2b/firma/${this.guid}`; };
    public static get orderApiUrl(): string { return `https://servis.glopark.com/api-order-v2/api/firma/${this.guid}`; };
    public static get circleApiUrl(): string { return `https://servis.glopark.com/api-circle/api/firma/${this.guid}`; };
    public static get Authorization(): string { return `Basic bW9iaW1ib19kZXY6YTV6eGZ0N1Ah`; };
    public static get successUrl(): string { return `https://pay.glopark.com`; };
    public static get errorUrl(): string { return `https://pay.glopark.com`; };

    public static get orderPrefix(): string { return `m`; };

    public static get payGloparkUrl(): string { return `https://pay.glopark.com`; };

    //local
    // public static get gloparkApiUrl(): string { return `http://localhost:56690/api/b2c/${this.guid}`; };
    // public static get b2cApiUrl(): string { return `http://localhost:56690/api/b2c/firma/${this.guid}`; };
    // public static get pureB2cApiUrl(): string { return `http://localhost:56690/api/b2c`; };
    // public static get payGloparkUrl(): string { return `http://localhost:52257/`; };
    // public static get payGloparkUrl(): string { return `http://localhost:52257/`; };
}