<div class="lightBox pureLightbox formLightLB" style="display: block;">
    <div class="content">
        <span class="closeBtn"></span>
        <button type="button" class="close" (click)="close('Save click')">
            <span aria-hidden="true">&times;</span>
        </button>

        <span class="title">
            <span>{{selectedAddress.Id ? 'Adres Düzenle' : 'Yeni Adres Ekle'}}</span>
            <!-- <span *ngIf="selectedAddress.Id">Adres Düzenle</span> -->
            <!-- <span *ngIf="!selectedAddress.Id">Yeni Adres Ekle</span> -->
        </span>
        <form #addressForm="ngForm" name="addressForm" class="form formLight formFloatPlaceholder addressField">
            <!-- <form class="form formLight formFloatPlaceholder addressField " autocomplete="off"> -->
            <div class="leftF">
                <div class="formfield" [ngClass]="{'error': !validateInput(addressForm.controls.addressName)}">
                    <input id="addressName" name="addressName" type="text" class="is-M addressName float-placeholder"
                        maxlength="255" [(ngModel)]="selectedAddress.AdresBaslik"
                        [attr.data-empty]="selectedAddress.AdresBaslik ? false : true" required>
                    <label for="addressName">Adres Başlığı </label>
                    <div *ngIf="!validateInput(addressForm.controls.addressName)" class="text text-danger">
                        Lütfen adres başlığı girin.
                    </div>
                </div>
                <div class="formfield" [ngClass]="{'error': !validateInput(addressForm.controls.fullName)}">
                    <input id="fullName" type="text" name="fullName" class="is-M fullName float-placeholder"
                        [(ngModel)]="selectedAddress.AdiSoyadi"
                        [attr.data-empty]="selectedAddress.AdiSoyadi ? false : true" required>
                    <label for="fullName">Adı Soyadı</label>
                    <div *ngIf="!validateInput(addressForm.controls.fullName)" class="text text-danger">
                        Lütfen ad soyadı girin.
                    </div>

                </div>
                <div class="formfield formLight__select is-S"
                    [ngClass]="{'error': !validateInput(addressForm.controls.cityId)}">
                    <div class="selectStyle">
                        <select id="cityId" name="cityId" class="custom" (ngModelChange)="onChangeCity()"
                            [(ngModel)]="selectedAddress.CityId">
                            <option value="undefined" selected>İl</option>
                            <option [ngValue]="city.CityID" *ngFor="let city of addressService.cityList">
                                {{city.CityName}}</option>
                        </select>
                    </div>

                    <div *ngIf="!validateInput(addressForm.controls.cityId)" class="text text-danger">
                        Lütfen şehir seçin.
                    </div>
                </div>
                <div class="formfield formLight__select district is-S"
                    [ngClass]="{'error': !validateInput(addressForm.controls.townId)}">
                    <div class="selectStyle">
                        <select id="townId" name="townId" class="custom" (ngModelChange)="onChangeTown()"
                            [(ngModel)]="selectedAddress.TownId">
                            <option value="undefined" selected>İlçe</option>
                            <option [ngValue]="town.TownID" *ngFor="let town of addressService.townList">
                                {{town.TownName}}</option>
                        </select>
                    </div>
                    <div *ngIf="!validateInput(addressForm.controls.townId)" class="text text-danger">
                        Lütfen ilçe seçin.
                    </div>
                </div>

                <div class="formfield formLight__select is-S"
                    [ngClass]="{'error': !validateInput(addressForm.controls.districtId)}">
                    <div class="selectStyle">
                        <select id="districtId" name="districtId" class="custom" (ngModelChange)="onChangeDistrict()"
                            [(ngModel)]="selectedAddress.DistrictId">
                            <option value="undefined" selected>Semt</option>
                            <option [ngValue]="district.DistrictID"
                                *ngFor="let district of addressService.districtList">
                                {{district.DistrictName}}</option>
                        </select>
                    </div>
                    <div *ngIf="!validateInput(addressForm.controls.districtId)" class="text text-danger">
                        Lütfen semt seçin.
                    </div>
                </div>
                <div class="formfield formLight__select neighborhood is-S"
                    [ngClass]="{'error': !validateInput(addressForm.controls.neighborhoodId)}">
                    <div class="selectStyle">
                        <select id="neighborhoodId" name="neighborhoodId" class="custom"
                            [(ngModel)]="selectedAddress.NeighborhoodId">
                            <option value="undefined" selected>Mahalle/Köy</option>
                            <option [ngValue]="neighborhood.NeighborhoodID"
                                *ngFor="let neighborhood of addressService.quarterList">
                                {{neighborhood.NeighborhoodName}}</option>
                        </select>
                    </div>
                    <div *ngIf="!validateInput(addressForm.controls.neighborhoodId)" class="text text-danger">
                        Lütfen mahalle/köy seçin.
                    </div>
                </div>

                <!-- <div class="formfield postcode">
                    <input id="postalCode" name="postCode" type="text" class="is-S postCode float-placeholder"
                        disabled="" value="03302" data-empty="false">
                    <label for="postalCode">Posta Kodu</label>
                    <div class="errorMessage" data-errormessagefor="postalCode">
                        <div class="errorText"></div>
                    </div>
                </div> -->
                <div class="formfield" [ngClass]="{'error': !validateInput(addressForm.controls.address)}">
                    <textarea name="address" id="address" class="is-M address" placeholder="Adres"
                        [(ngModel)]="selectedAddress.Adres" required></textarea>
                    <div *ngIf="!validateInput(addressForm.controls.address)" class="text text-danger">
                        Lütfen adres girin.
                    </div>
                </div>
            </div>
            <div class="rightF">
                <div class="formfield" [ngClass]="{'error': !validateInput(addressForm.controls.gsm)}">
                    <input id="gsm" type="text" name="gsm" class="is-M gsm float-placeholder" maxlength="10"
                        [(ngModel)]="selectedAddress.CepTelefonu"
                        [attr.data-empty]="selectedAddress.CepTelefonu ? false : true" required>
                    <label for="gsm">Cep Telefonu</label>
                    <!-- <div class="formFieldInfo">
                        <span>Siparişin gönderimi ve takibi için talep edilir.</span>
                    </div> -->
                    <div *ngIf="!validateInput(addressForm.controls.gsm)" class="text text-danger">
                        Lütfen cep telefonu numarası girin.
                    </div>
                </div>
                <div class="invTypeHolder individualHolder">
                    <div class="formfield" [ngClass]="{'error': !validateInput(addressForm.controls.tcNO)}">
                        <input id="tcNO" type="text" name="tcNO" class="is-M tcId float-placeholder" maxlength="11"
                            [(ngModel)]="selectedAddress.TcKimlikNo"
                            [attr.data-empty]="selectedAddress.TcKimlikNo ? false : true" required>
                        <label for="tcNO">TC Kimlik No</label>
                        <div *ngIf="!validateInput(addressForm.controls.tcNO)" class="text text-danger">
                            Lütfen TC kimlik no girin.
                        </div>
                    </div>
                </div>

                <div class="formfield invoiceType hRadioBtns">
                    <span class="type"><b>Fatura Türü</b></span>
                    <input type="radio" class="customRadio individual" id="Individual" name="invoiceType" [value]="0"
                        [(ngModel)]="selectedAddress.FaturaTuru">
                    <label for="Individual" class="svgIcon">Bireysel</label>
                    <input type="radio" class="customRadio corporate" id="Corporate" name="invoiceType" [value]="1"
                        [(ngModel)]="selectedAddress.FaturaTuru"
                        (ngModelChange)="invoiceTypeChanged(selectedAddress.FaturaTuru)">
                    <label for="Corporate" class="svgIcon">Kurumsal</label>
                </div>
                <div class="invTypeHolder corporateHolder" style="display: block;"
                    *ngIf="selectedAddress.FaturaTuru == 1">
                    <div class="formfield" [ngClass]="{'error': !validateInput(addressForm.controls.companyName)}">
                        <input id="companyName" name="companyName" type="text"
                            class="is-M companyName float-placeholder" maxlength="255"
                            [(ngModel)]="selectedAddress.FirmaAdi"
                            [attr.data-empty]="selectedAddress.FirmaAdi ? false : true" required>
                        <label for="companyName">Firma Adı</label>
                        <div *ngIf="!validateInput(addressForm.controls.companyName)" class="text text-danger">
                            Lütfen firma adı girin.
                        </div>
                    </div>
                    <div class="formfield" [ngClass]="{'error': !validateInput(addressForm.controls.taxOffice)}">
                        <input id="taxOffice" type="text" name="taxOffice" class="is-S taxHouse float-placeholder"
                            [(ngModel)]="selectedAddress.VergiDairesi" maxlength="100"
                            [attr.data-empty]="selectedAddress.VergiDairesi ? false : true" required>
                        <label for="taxOffice">Vergi Dairesi</label>
                        <div *ngIf="!validateInput(addressForm.controls.taxOffice)" class="text text-danger">
                            Lütfen vergi dairesi girin.
                        </div>
                    </div>
                    <div class="formfield" [ngClass]="{'error': !validateInput(addressForm.controls.taxId)}">
                        <input id="taxId" name="taxId" type="text" class="is-S taxId float-placeholder" minlength="10"
                            maxlength="10" [(ngModel)]="selectedAddress.VergiNumarasi"
                            [attr.data-empty]="selectedAddress.VergiNumarasi ? false : true" required>
                        <label for="taxId">Vergi Numarası</label>
                        <div *ngIf="addressForm.controls.taxId?.touched && addressForm.controls.taxId?.errors?.required"
                            class="text text-danger">
                            Lütfen vergi numarası girin.
                        </div>
                        <div *ngIf="addressForm.controls.taxId?.touched && addressForm.controls.taxId?.errors?.minlength"
                            class="text text-danger" style="max-width: 175px;">
                            Girilen değer en az 10 karakter olmalıdır.
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div class="btnHolder">
            <input type="button" class="btn btnBlack js-updateAddressBtn" data-toggle="modal" (click)="saveAddress()"
                [disabled]="!validateForm(addressForm)" value="{{selectedAddress.Id ? 'Güncelle' : 'Ekle'}}">
        </div>
    </div>
</div>