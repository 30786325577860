import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Constants } from '../constants';

@Injectable()
export class FilterService {
    attributes:Array<any>;
    selected = {};
    filterBar = false;
    constructor(
        private http: HttpClient
    ){ }

    getAttributes(collectionId, filters = [], search?, handleUrl?): Observable<any> {
        this.attributes = []
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': Constants.Authorization,
                'Content-Type': 'application/json'
            })
        }
        if(search) {
            filters.push({ 'name': 'SEARCH', values: [search] })
        }
        
        let requestUrl = `${Constants.b2cApiUrl}/attribute/list/${Constants.platformId}/${Constants.apiId}/${collectionId}?handleUrl=${handleUrl}`
        return this.http.post(requestUrl, filters, httpOptions)
        .pipe(map(res => {
            if (res['status']) {
                this.attributes = res['data'];
                if (Object.entries(this.selected).length === 0 && this.selected.constructor === Object) {
                    this.organizeSelected();
                }
            }
        }))
    }

    getAttributesValue(name) {
        let attributes = this.attributes.find(a => { return a.Name == name });

        return attributes.Values.map(v => {
            return { Name: v.Value, Count: v.Count };
        })
    }

    organizeSelected() {
        for (const a of this.attributes) {
            this.selected[a.Name] = {};
        }
    }

    createFilterQuery() {
        let filterQuery = [];
        for (const attr in this.selected) {
            let filter = { name: attr, values: []};
            if (this.selected.hasOwnProperty(attr)) {
                for (const key in this.selected[attr]) {
                    if (this.selected[attr].hasOwnProperty(key)) {
                        if (attr == 'FİYAT') {
                            let val = `${this.selected[attr][key].lower};${this.selected[attr][key].upper}` ;
                            filter.values.push(val)
                        }else {
                            if (this.selected[attr][key]) {
                                filter.values.push(key)
                            }
                        }
                    }
                }
                filterQuery.push(filter);
            }
        }

        return filterQuery;
    }

}