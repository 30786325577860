import { Component } from '@angular/core';
import { AppService } from './shared/service/app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  ios = false;
  android = false;
  title = 'Mobimbo';

  uuid = localStorage.getItem('uuid');

  constructor(
    private appService:AppService
  ) {
    if (!this.uuid) {
      localStorage.setItem('uuid', Math.random().toString());
    }

    this.appService.settings().subscribe(res => { });

    console.log('bavasd',navigator.platform)
    if(navigator.platform == 'iPod' || navigator.platform == 'iPad' || navigator.platform == 'iPhone'){
      this.ios = true
    }
    else{
      this.android = true
    }

    // if(navigator.platform == 'Win32' || navigator.platform == 'Win16' || navigator.platform == 'Android'){
    //   this.android = true
    //   console.log('true')
    // }

  }
}
