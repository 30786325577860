<!-- <div class="w-100 p-3 col-12 mobile" *ngIf="ios">
<div class="row">
        <div class="col-2 text-right">
            <img height="45" style="border-radius:5px;" src="https://is2-ssl.mzstatic.com/image/thumb/Purple113/v4/6b/57/ae/6b57ae80-7211-a3c1-a706-3325bc49e99d/AppIcon-1x_U007emarketing-0-10-0-0-85-220.png/230x0w.png" alt="">
        </div>
    
        <div class="col-6 text-left">
            <p class="fw">mobimbo.com</p>
            <p class="fw2">Glopark</p>
            <p class="fw2">İNDİR - App Store'da</p>
        </div>

        <div class="col-4 py-2">
            <a class="text-color" target="_blank" href="https://apps.apple.com/us/app/mobimbo/id1509691526">Görüntüle</a>
        </div>
    </div>
</div>

<div class="w-100 p-3 col-12 mobile" *ngIf="android">
    <div class="row">
            <div class="col-2  text-right">
                <img height="45" style="border-radius:5px;" src="https://is2-ssl.mzstatic.com/image/thumb/Purple113/v4/6b/57/ae/6b57ae80-7211-a3c1-a706-3325bc49e99d/AppIcon-1x_U007emarketing-0-10-0-0-85-220.png/230x0w.png" alt="">
            </div>
        
            <div class="col-6 text-left">
                <p class="fw">mobimbo.com</p>
                <p class="fw2">Glopark</p>
                <p class="fw2">İNDİR - Play Store'da</p>
            </div>
    
            <div class="col-4 py-2">
                <a class="text-color" target="_blank" href="https://play.google.com/store/apps/details?id=com.glopark.mobimbo&hl=tr">Görüntüle</a>
            </div>
        </div>
    </div> -->

<app-loader></app-loader>
<router-outlet></router-outlet>
