import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './shared/guard/auth.guard';

export const routes: Routes = [
  {
    path: 'giris',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'collection/:handleUrl',
    loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsPageModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'sepetim',
    loadChildren: () => import('./pages/my-cart/my-cart.module').then(m => m.MyCartPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'product/:handleUrl',
    loadChildren: () => import('./pages/product-detail/product-detail.module').then(m => m.ProductDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'odeme',
    loadChildren: () => import('./pages/checkout/checkout.module').then(m => m.CheckoutPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'pages/:name',
    loadChildren: () => import('./pages/pages/pages.module').then(m => m.PagesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'siparis/:id/:type',
    loadChildren: () => import('./pages/order-result/order-result.module').then(m => m.OrderResultPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'hesabim',
    loadChildren: () => import('./pages/my-account/my-account.module').then(m => m.MyAccountPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'musteri-hizmetleri/:id',
    loadChildren: () => import('./pages/faq/faq.module').then(m => m.FAQPageModule)
  },
  {
    path: 'siparislerim',
    loadChildren: () => import('./pages/orders/orders.module').then(m => m.OrdersPageModule),
    canActivate: [AuthGuard]
  }
]; 

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    //preloadingStrategy: PreloadAllModules,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'reload'
})],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
