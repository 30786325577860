<a class="overlay-sidebar-header" [class.overlay-sidebar-header-open]="openSide" (click)="closeOverlay()"></a>
<div class="responsive-btn">
    <a (click)="toggleSidebar()" style="font-size: 14px; padding: 5px;">
        <i class="fa fa-bars text-white" style="color:#fff !important;" aria-hidden="true"></i>
    </a>
</div>
<div class="navbar m-l-auto bg-darks" id="togglebtn" [class.openSidebar]="openSide">
    <div class="responsive-btn">
        <a (click)="toggleSidebar()">
            <h5 class="text-white">Kapat</h5>
        </a>
    </div>
    <ul class="main-menu fill">
        <!-- 1st Level Menu -->
        <li *ngFor="let menuItem of collectionService.storyList" [class]="menuItem.megaMenu ? 'mega-menu' : ''">
            <!-- Sub -->
            <a [routerLink]="['/collection/' + menuItem.HandleUrl]" >
                {{menuItem.KoleksiyonAdi}}
            </a>
            <!-- 2nd Level Menu -->
            <ul [class.opensubmenu]="isActive(menuItem.KoleksiyonAdi)">
                <!-- Simple Menu Start-->
                <li *ngFor="let collection of collectionService.collectionWithChildren(menuItem.Id).Children" [class.sub-menu]="collection.Children">
                    <!-- Sub -->
                    <a [routerLink]="['/collection/' + collection.Item.HandleUrl]">
                        {{collection.Item.KoleksiyonAdi}}
                    </a>

                    <ul style="top:0 !important;">
                        <li *ngFor="let item of collection.Children">
                            <a [routerLink]="['/collection/' + item.Item.HandleUrl]">{{item.Item.KoleksiyonAdi}}</a>
                        </li>

                    </ul>
                </li>
            </ul>
        </li>
    </ul>
</div>