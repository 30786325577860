import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnChanges } from '@angular/core';
import { ProductTags, TagFilter } from '../../..//model/e-commerce/product.model';
import { ActivatedRoute } from '@angular/router';
import { Router } from 'express';

declare var $: any;

@Component({
  selector: 'app-brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.scss']
})
export class BrandComponent implements OnInit, OnChanges {

  @Input() tagsFilters: ProductTags[] = [];
  @Output() tagFilters: EventEmitter<ProductTags[]> = new EventEmitter<ProductTags[]>();

  // Array
  public checkedTagsArray: any[] = [];

  constructor() { }

  // value checked call this function
  checkedFilter(event) {
    let index = this.checkedTagsArray.indexOf(event.target.value);  // checked and unchecked value
    if (event.target.checked)
      this.checkedTagsArray.push(event.target.value); // push in array checked value
    else
      this.checkedTagsArray.splice(index, 1);  // removed in array unchecked value  

    this.tagFilters.emit(this.checkedTagsArray);
  }

  ngOnInit() {
    this.tagsFilters['Values'] = this.tagsFilters['Values'].filter(function (el) {
      return el['Value'] != null && el['Value'] != '';
    });

    this.tagsFilters['Values'].sort(function (a, b) {
      if (a.Value == null) {
        a.Value = "Diğer";
      }
      return a.Value.localeCompare(b.Value)
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tagsFilters'] !== undefined && !changes['tagsFilters'].firstChange)
      this.ngOnInit();
  }
}